@import '~antd/dist/antd.css';

.card-main {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 14px;
}

.ant-layout {
  background: transparent;
}

.layout-sider {
  width: 256px !important;
  min-width: 256px !important;
  max-width: 256px !important;
}

.trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 58px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
}

.logo > img {
  height: 100%;
}

.site-layout .site-layout-background {
  background: #fff;
}

.settings-application-form label {
  white-space: pre-wrap;
}

.login-form {
  max-width: 250px;
  margin: 0 auto;
}

.login-form .ant-row {
  width: 100%;
  display: block;
  max-width: 100%;
}

.login-form .ant-row .ant-col {
  width: 100%;
  display: block;
  max-width: 100%;
}

.login-form-button {
  width: 100%;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.spin-flex .ant-spin-container {
  display: flex;
}